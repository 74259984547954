<template>
  <!-- 邀请好友 -->
  <div>
    <div class="bg_gray"></div>
    <div class="kefulist">
      <headbox title="邀请码" bgColor="#fff" isborder="true" :isbackPath="true" path="person"></headbox>
      <!-- 二维码 -->
      <center class="orcodebox">
        <div ref="qrcode" id="qrcode"></div>
        <div class="h2">专属邀请码: {{ invitationCode }}</div>
        <div class="details">
          截图保存，发给微信好友使用扫一扫即可邀请绑定好友
        </div>
      </center>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import headbox from "@/components/head";

export default {
  components: {
    QRCode,
    headbox
  },
  data() {
    return {
      invitationCode: ""
    };
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    this.invitationCode = userInfo.referrerCode;
    this.$nextTick(() => {
      new QRCode("qrcode", {
        width: 200,
        height: 200,
        text:
          process.env.VUE_APP_WEBSITE +
          "/?referrerCode=" +
          userInfo.referrerCode
      });
    });
  }
}
</script>

<style lang="scss" scoped="true">
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.kefulist {
  position: relative;
  z-index: 1;
}
.orcodebox {
  margin: 300px auto;
  width: 400px;
  height: 360px;
}
.h2 {
  margin: 15px 0 30px;
  color: #333333;
  font-size: 36px;
}
.details {
  color: #999999;
  font-size: 28px;
}
</style>
