var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "kefulist" },
      [
        _c("headbox", {
          attrs: {
            title: "邀请码",
            bgColor: "#fff",
            isborder: "true",
            isbackPath: true,
            path: "person"
          }
        }),
        _c("center", { staticClass: "orcodebox" }, [
          _c("div", { ref: "qrcode", attrs: { id: "qrcode" } }),
          _c("div", { staticClass: "h2" }, [
            _vm._v("专属邀请码: " + _vm._s(_vm.invitationCode))
          ]),
          _c("div", { staticClass: "details" }, [
            _vm._v(" 截图保存，发给微信好友使用扫一扫即可邀请绑定好友 ")
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }